/*------------------------------フォーム------------------------------*/
.wpcf7 {
  margin: 0 auto;
  // width: calc(100% - 120px);
  max-width: 1000px;
  padding-bottom: 100px;
  letter-spacing: 0;
}

@media only screen and (max-width: 768px) {
  .wpcf7 {
    margin: 0 auto;
    // width: calc(100% - 60px);
    padding-bottom: 86px;
  }
}

.wpcf7 form p {
  line-height: 2;
  font-size: 14px;
  margin-bottom: 70px;
}

@media only screen and (max-width: 768px) {
  .wpcf7 form p {
    margin-bottom: 50px;
  }
}

.wpcf7 form p strong {
  color: #FF6E6E;
}

.wpcf7 form p a {
  text-decoration: underline;
}

.wpcf7 form p a:hover {
  opacity: 0.6;
}

.wpcf7 form .inputArea {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
}

.wpcf7 form .inputArea label {
  font-size: 18px;
}

.wpcf7 form .inputArea label .required {
  display: inline-block;
  width: 58px;
  height: 23px;
  line-height: 23px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  background: #FF6E6E;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 8px;
}

.wpcf7 form .inputArea label .optional {
  display: inline-block;
  width: 58px;
  height: 23px;
  line-height: 23px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  background: #C4C4C4;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 8px;
}

.wpcf7 form .inputArea input[type="text"],
.wpcf7 form .inputArea input[type="email"],
.wpcf7 form .inputArea input[type="tel"] {
  margin-top: 10px;
  background: #F8F8F8;
  border: solid 1px #c4c4c4;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  width: 100%;
}

.wpcf7 form .inputArea input::-moz-placeholder {
  color: #c4c4c4;
}

.wpcf7 form .inputArea input::placeholder {
  color: #c4c4c4;
}

.wpcf7 form .inputArea textarea {
  margin-top: 10px;
  background: #F8F8F8;
  border: solid 1px #c4c4c4;
  padding: 15px;
  width: 100%;
  height: 160px;
}

.wpcf7 form .inputArea textarea::-moz-placeholder {
  color: #c4c4c4;
}

.wpcf7 form .inputArea textarea::placeholder {
  color: #c4c4c4;
}

// .classroomWrap {
//   span.wpcf7-form-control-wrap {

//   }
// }

span[data-name="classroom"] {
  margin-top: 10px;
  display: block;
  position: relative;
  border-block-end-width: 100%;
  height: 100%;
}

span[data-name="classroom"]:before {
  content: "";
  position: absolute;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  width: 8px;
  height: 8px;
  right: 24px;
  top: 23px;
  transform: rotate(45deg);
  z-index: 10;
}

.wpcf7 form .inputArea select {
  height: 60px;
  line-height: 60px;
  width: 100%;
  border: solid 1px #c4c4c4;
  padding: 0 15px;
  appearance: none;
  -webkit-appearance: none;
  position: relative;
}


@media only screen and (max-width: 768px) {
  .wpcf7 form .inputArea select {
    width: 100%;
  }
}

.wpcf7 form .inputArea select:forcus {
  outline: none;
}

.wpcf7 form .inputArea+.inputArea {
  margin-top: 30px;
}

.wpcf7 form .wpcf7-acceptance {
  display: block;
  margin-top: 50px;
  text-align: center;
  font-size: 18px;
}

@media only screen and (max-width: 768px) {
  .wpcf7 form .wpcf7-acceptance {
    margin-top: 30px;
  }
}

.wpcf7 form .wpcf7-acceptance .privacypolicy {
  margin-left: 8px;
}

.wpcf7 form .cf7-btnWrap {
  margin-top: 30px;
}

.wpcf7 form .cf7-btnWrap .cf7-submitBtn {
  margin: 0 auto;
  width: 100%;
  max-width: 370px;
  height: 70px;
  border-radius: 5px;
  color: #fff;
  background: #000;
  font-size: 16px;
  line-height: 70px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
}

.wpcf7 form .cf7-btnWrap .cf7-submitBtn::before {
  content: "";
  background: url(../images/common/arrow_white.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.wpcf7 form .cf7-btnWrap .cf7-submitBtn:hover {
  background: #fff;
  color: #000;
  border: solid 1px #000;
}

.wpcf7 form .cf7-btnWrap .cf7-submitBtn:hover::before {
  background: url(../images/common/arrow_black.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.wpcf7 form .cf7-btnWrap .cf7-submitBtn input {
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.payment-required .wpcf7-response-output {
  border-color: #FF6E6E !important;
}

.wpcf7-list-item-label a.privacypolicy {
  text-decoration: underline;
}

.wpcf7-list-item-label a.privacypolicy:hover {
  text-decoration: none;
  opacity: 0.6;
}


//mf add 1125
.cf7-contact {
  @if $type =="" {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  @if $type ==sp {
    gap: 4px;
  }

  @if $type ==pc {
    gap: 4px;
  }

  .wpcf7-list-item {
    @if $type =="" {
      margin-left: 0 !important;
      display: flex;
      justify-content: flex-start;
    }

    @if $type ==sp {}

    @if $type ==pc {}

    input[type="radio"] {
      @if $type =="" {
        margin-right: 6px;
      }
    }

    span {
      @if $type =="" {}


      @if $type ==sp {
        font-size: 16px;
      }

      @if $type ==pc {
        font-size: 18px;

      }
    }
  }
}