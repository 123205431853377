@charset "UTF-8";

// IMPPRT INIT

@import "_setting";
$type: "";
@import "_form";

// FOR SMARTPHONE DISPLAY SIZE
//-----------------------------------------------------------------
@media only screen and (max-width: 768px) {
	$type: sp;
	@import "_form";
}

// FOR LARGE DISPLAY SIZE
//-----------------------------------------------------------------
@media only screen and (min-width: 769px) {
	$type: pc;
	@import "_form";
}

// FOR TABLET DISPLAY SIZE
//-----------------------------------------------------------------
@media screen and (min-width:$breakpoint_pc) and (max-width:$breakpoint_tb) {
  $type: tb;
  @import "_form";
}

// STYLE FOR PRINT
//-----------------------------------------------------------------
@media print {
	$type: print;
}
